.container {
  color: inherit;
  font-size: 18px;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .container {
    font-size: 14px;
    text-align: right;
    white-space: wrap;
  }
}
