.container {
  gap: 24px;
}

.groups {
  justify-content: space-around;
}

.item {
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
}

.item.active {
  background-color: var(--secondary);
  color: var(--on-secondary);
}

.group {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.grid {
  border-collapse: collapse;
  table-layout: fixed;
}

.grid td {
  padding: 8px;
}

.grid th:nth-child(1),
.grid th:nth-child(2) {
  width: calc(50% - 300px);
}

.grid th:nth-child(3) {
  width: 150px;
}

.grid th:nth-child(4) {
  width: 150px;
}

.grid td:nth-child(1),
.grid td:nth-child(2) {
  vertical-align: top;
  width: calc(50% - 300px);
}

.grid td:nth-child(3),
.grid td:nth-child(4) {
  text-align: right;
}

.toggler {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-right: 8px;
}

.togglerDown {
  transform: rotate(90deg);
}

.toggleContainer {
  cursor: pointer;
  user-select: none;
}
