.container {
  width: 100%;
}

.actions {
  align-items: center;
  display: flex;
  gap: 20px;
  padding: 0 20px;
  width: 100%;
}

.link {
  color: inherit;
  text-decoration: underline;
  white-space: nowrap;
}
