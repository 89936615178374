.container {
  background-color: white;
  border-radius: 16px;
  box-shadow: 2px 2px 5px -3px rgba(34, 60, 80, 0.6);
  padding: 8px 12px;
  max-width: 520px;
  width: fit-content;
}

.content::after {
  display: table;
  clear: both;
  content: '';
}

.text {
  display: inline-block;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.direction_in {
  background-color: #ecf9fb;
  margin-left: auto;
}

.status {
  color: #98a8c5;
  display: flex;
  flex-wrap: nowrap;
  float: right;
  transform: translateY(4px);
}

.time {
  display: inline-block;
  font-size: 80%;
  margin-left: 8px;
}

.icon {
  margin-left: 4px;
}

.direction_in .status {
  color: #41a8e6;
}

.wrapper {
  margin: -1px -16px;
  padding: 1px 16px;
  width: calc(100% + 32px);
}

.wrapper.is_selected {
  background-color: rgba(0, 0, 0, 0.1);
}
