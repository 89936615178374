@font-face {
  font-family: "BasisGrotesquePro-Mono";
  src: url("./BasisGrotesquePro-Mono.woff2") format("woff2"),
       url("./BasisGrotesquePro-Mono.ttf") format("truetype");
}

@font-face {
  font-family: "BasisGrotesquePro-Regular";
  src: url("./BasisGrotesquePro-Regular.woff2") format("woff2"),
       url("./BasisGrotesquePro-Regular.ttf") format("truetype");
}
