.container {
  position: relative;
  font-size: 16px;
}

.list {
  left: 0;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  transition: opacity 0.3s;
}

.container > .list > * {
  font-size: 16px;
}

.open > .list {
  max-height: none;
  opacity: 1;
  pointer-events: all;
}
