:root {
  --primary: #847d7d;
  --on-primary: white;

  --secondary: #77a3cc;
  --on-secondary: white;

  --color-error: #d91d0b;
  --color-success: #198754;

  --tile: #847d7d20;
  --dark-tile: #847d7d40;

  --font-action: 300 17px/24px 'BasisGrotesquePro-Mono', sans-serif;
}
