.container {
  border: 1px solid var(--dark-tile);
  align-items: flex-start;
  display: flex;
  padding: 8px 16px;
}

.addons {
  flex: 0 1 0;
}

.input {
  flex: 1 0 0;
}
