.container {
  width: 100%;
}

.content {
  position: relative;
}

.success {
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: opacity 0.3s;
  top: 0;
  width: 100%;
}

.success.active {
  opacity: 1;
  pointer-events: auto;
}
