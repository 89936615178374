.text {
  font-size: 16px;
  line-height: 24px;
}

.view_primary-large {
  font-size: 18px;
}

.view_primary {
  font-size: 16px;
  line-height: 24px;
}

.view_primary-small {
  font-size: 14px;
  line-height: 20px;
}

.color_primary {
  color: #0e0e0e;
}

.color_secondary {
  color: rgba(60, 60, 67, 0.66);
}

.color_error {
  color: var(--color-error);
}

.color_success {
  color: var(--color-success);
}

.pre {
  white-space: pre-line;
}

.title {
  font-family: BasisGrotesquePro-Regular, sans-serif;
  font-weight: 700;
  letter-spacing: -0.3px;
}

.size_xlarge {
  font-size: 48px;
  line-height: 64px;
}

.size_large {
  font-size: 40px;
  line-height: 48px;
}

.size_medium {
  font-size: 32px;
  line-height: 40px;
}

.size_small {
  font-size: 24px;
  line-height: 32px;
}

.size_xsmall {
  font-size: 20px;
  line-height: 24px;
}

.weight_normal {
  font-weight: 400;
}

.block {
  align-items: baseline;
  display: flex;
  width: 100%;
}

.text.size_inherit {
  font-size: inherit;
  line-height: inherit;
}

.text.italic {
  font-style: italic;
}

.text.size_medium {
  font-size: 14px;
  line-height: 24px;
}

.text.size_large {
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .size_xlarge {
    font-size: 24px;
    line-height: 36px;
  }

  .size_large {
    font-size: 20px;
    line-height: 36px;
  }

  .size_medium {
    font-size: 18px;
    line-height: 24px;
  }

  .size_small {
    font-size: 16px;
    line-height: 20px;
  }

  .size_xsmall {
    font-size: 14px;
    line-height: 20px;
  }
}
