.container {
  align-items: center;
  background: var(--secondary);
  border-radius: 8px;
  color: var(--on-secondary);
  display: flex;
  /*  gap: 12px;
  padding: 24px;*/
  gap: 8px;
  padding: 12px;
  position: relative;
  width: 100%;
}

.container.ready {
  cursor: pointer;
}
