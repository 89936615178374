.container {
  justify-content: space-evenly;
  margin-bottom: 24px;
  width: 100%;
}

.link {
  color: inherit;
  text-decoration: none;
  text-underline-offset: 8px;
}

.link.active {
  text-decoration: underline;
}

.link:hover {
  text-decoration: underline;
}
