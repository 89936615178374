.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  padding: 64px 0;
  width: calc(100% - 40px);
}

.logo {
  align-items: center;
  display: flex;
  gap: 8px;
  color: inherit;
  text-decoration: inherit;
}

.logoText {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.regions {
  letter-spacing: 0.5px;
}

.mirror {
  opacity: 0;
  pointer-events: none;
}

.links {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.socials {
  align-items: center;
  display: flex;
  gap: 40px;
}

.social {
  height: 32px;
  width: 32px;
}

.social img {
  height: 32px;
  width: 32px;
}

.phone {
  color: inherit;
  text-align: center;
  text-decoration: none;
}
