.container {
  color: transparent;
  display: block;
  opacity: 0;
  pointer-events: none;
}

.active {
  opacity: 1;
}

.container {
  animation: shine-lines 2.6s infinite linear;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 1280px;
}

@keyframes shine-lines {
  0% {
    background-position: 0;
  }

  40%,
  100% {
    background-position: 800зч;
  }
}
