.grid {
  display: flex;
}

.navigation {
  flex: 0 0 320px;
  padding: 20px;
}

.container {
  align-items: stretch;
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1280px;
  padding: 20px 0;
  width: calc(100% - 40px);
}
