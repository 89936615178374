.container {
  align-items: center;
  display: flex;
  width: 100%;
}

.status {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
  width: 24px;
}

.names {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.name {
  font-size: 14px;
}

.fileName {
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

.fileName:hover {
  text-decoration: underline;
}

.upload {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 96px;
  justify-content: flex-start;
  height: 48px;
  width: 96px;
}

.statusText {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.icon {
  display: inline-flex;
}
