.container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.view_mobile {
  max-width: 480px;
}

.view_inplace {
  padding: 0;
}
