.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
}

.messages {
  height: calc(100vh - 400px);
}

.input {
  flex: 0 1 0;
}
