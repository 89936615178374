.container {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  height: 36px;
  width: 36px;
}
