.container {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  color: inherit;
  justify-content: center;
  gap: 12px;
  font: var(--font-action);
  height: 40px;
  letter-spacing: -0.3px;
  opacity: 1;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  transition: opacity 0.3s;
}

.color_primary {
  background: var(--primary);
  border: none;
  color: var(--on-primary);
}

.color_transparent {
  background: transparent;
  border: none;
  color: inherit;
}

.is_block {
  width: 100%;
}

.is_loading {
  opacity: 0.4;
  pointer-events: none;
}
