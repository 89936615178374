.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 4px 0;
  min-height: 48px;
}

.highlight {
  background-color: #efefef;
}

.select {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
  width: 48px;
}

.icon {
  height: 24px;
  width: 24px;
}
