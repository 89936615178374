.container {
  background: url('./blank.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 48px;
  width: 48px;
}

.checked {
  background-image: url('./checked.svg');
}
