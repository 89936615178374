.container {
  padding: 20px 0;
}

.actions {
  align-self: flex-start;
}

.radios {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 30px;
}

.check {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 40px;
}

.checkInput {
  cursor: pointer;
  height: initial;
  width: initial;
}
