.container {
  /*background-color: #a6b9ee;*/
  background: var(--tile);
  border: 1px solid var(--dark-tile);
  border-bottom: none;
  flex-direction: column-reverse;
  height: 100%;
  gap: 2px;
  margin: 0 auto;
  max-width: 640px;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  scroll-snap-align: end;
  padding: 16px;
  width: 100%;
}

.is_empty {
  background: var(--tile);
  position: relative;
}

.message {
  left: 0;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
