.container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 1280px;
  width: calc(100% - 40px);
}

.frame {
  align-items: center;
  background: #f5f5f5;
  border-radius: 8px;
  gap: 44px;
  display: flex;
  padding: 44px;
  width: 100%;
}

.frame-delimiter {
  align-self: stretch;
  background: #212121;
  min-width: 1px;
  width: 1px;
}

@media screen and (max-width: 1024px) {
  .container {
    gap: 24px;
  }
  .frame {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  .frame-delimiter {
    min-height: 1px;
    height: 1px;
    width: 100%;
  }
}
