.container {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.contacts {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.phone {
  color: inherit;
  text-decoration: none;
}

.email {
  color: inherit;
  text-decoration: underline;
}
