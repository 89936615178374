.list {
  --list-gap: 0;
}

.actions {
  align-self: flex-start;
}

.item {
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 56px;
  padding: 0 16px;
  text-decoration: none;
  width: 100%;
}

.item:hover {
  background-color: var(--tile);
}

.groupName {
  background: #efefef;
  width: 100%;
}
