:root {
  --list-gap: 20px;
}

.container {
  align-items: stretch;
  display: flex;
  gap: var(--list-gap);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.orientation_vertical {
  flex-direction: column;
}

.orientation_horizontal {
  flex-direction: row;
}

.item {
  align-items: center;
  display: flex;
}
