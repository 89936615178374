body {
  font-family: BasisGrotesquePro-Regular, sans-serif;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  width: 100%;
}
