.container {
  align-items: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 8px;
  margin-bottom: 8px;
}

.name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container::before {
  background: red;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}
