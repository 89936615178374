.container {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 0 0 48px;
  height: 48px;
  opacity: 1;
  transition: opacity 0.3s;
  width: 48px;
}

.disabled {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

.loading {
  opacity: 0.4;
  pointer-events: none;
}

.icon_delete {
  background-image: url(../../../icons/delete.svg);
}

.icon_upload {
  background-image: url(../../../icons/upload.svg);
}

.icon_logout {
  background-image: url(../../../icons/logout.svg);
}
