.container {
  justify-content: space-evenly;
  width: 100%;
}

.link {
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
  padding: 8px;
}

.subLink {
  font-size: 16px;
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
  padding: 8px;
}

.container a:hover {
  text-decoration: underline;
}
