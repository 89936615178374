.container {
  opacity: 1;
  transition: opacity 0.3s;
}

.loading {
  opacity: 0.4;
  pointer-events: none;
}

.actions {
  align-self: flex-start;
}
