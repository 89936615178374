.container {
  opacity: 1;
  position: relative;
  transition: opacity 0.3s;
}

.loading {
  opacity: 0.6;
  pointer-events: none;
}

.label {
  left: 8px;
  position: absolute;
  transform: scale(calc(16 / 14)) translateY(12px) translateX(1px);
  transform-origin: left;
  transition: transform 0.3s;
}

.label.filled {
  transform: scale(1);
}

.input {
  height: 48px;
  padding-top: 20px;
  padding-bottom: 4px;
}

.skeleton {
  left: 10px;
  position: absolute;
  top: 20px;
  height: 20px;
  width: calc(100% - 20px);
}

.required {
  color: var(--color-error);
}
