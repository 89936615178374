:root {
  --spinner-circle-size: 12px;
}

.spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
  width: 100%;
}

@keyframes spinner-circle-animation {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.spinner-circle-1,
.spinner-circle-2,
.spinner-circle-3,
.spinner-circle-4 {
  animation: spinner-circle-animation infinite 1s;
  background: #333;
  border-radius: var(--spinner-circle-size);
  height: var(--spinner-circle-size);
  margin: 3px;
  width: var(--spinner-circle-size);
}

.spinner-circle-1 {
  animation-delay: 0;
}
.spinner-circle-2 {
  animation-delay: 0.1s;
}
.spinner-circle-3 {
  animation-delay: 0.2s;
}
.spinner-circle-4 {
  animation-delay: 0.3s;
}

.spinner.is_active {
  opacity: 1;
}
