.container {
  background-color: white;
  border: 1px solid #8c8c8c;
  border-radius: 8px;
  color: #212121;
  display: block;
  font: inherit;
  font-size: 16px;
  height: 40px;
  margin: 0;
  opacity: 1;
  outline: none;
  padding: 8px;
  transition: opacity 0.3s, border-color 0.3s;
  width: 100%;
}

.color_error {
  border-color: var(--color-error);
}
