.container {
  gap: 0;
}

.item {
  align-items: stretch;
  flex-direction: column;
}

.folder {
  background: white;
}

.folder:nth-child(odd) {
  background: #efefef;
}

.children {
  padding-left: 20px;
}
