.container {
  position: relative;
}

.control {
  background: transparent;
  border: none;
  display: flex;
  font: inherit;
  min-height: 36px;
  max-height: 40vh;
  line-height: 20px;
  resize: none;
  padding: 8px 0;
  width: 100%;
}

.measurer {
  visibility: hidden;
  overflow: hidden;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.input {
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}
