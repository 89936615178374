.container {
  background-color: white;
  height: 84px;
  left: 0;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 84px;
  margin: 0 auto;
  max-width: 1280px;
  width: calc(100% - 40px);
  white-space: nowrap;
}

.button {
  background: url('./back.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 56px;
  margin-left: -20px;
  width: 56px;
}

.stub {
  height: 84px;
}

.logo {
  align-items: center;
  display: flex;
  gap: 8px;
  color: inherit;
  text-decoration: inherit;
}

.logoText {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.regions {
  letter-spacing: 0.5px;
}

@media screen and (max-width: 1024px) {
  .content {
    width: 100%;
  }
}
