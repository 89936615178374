.container {
  align-items: stretch;
  display: flex;
}

.content {
  align-items: stretch;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-decoration: none;
}
